import { useUserStore } from "@/stores/useUserStore";
import { createRouter, createWebHistory } from "vue-router";

export const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'Root',
            redirect: '/ProductList'
        },
        // 商品详情页
        {
            path: '/ProductDetails',
            name: 'ProductDetails',
            component: () => import('@/pages/ProductDetails.vue'),
            meta: {
                needLogin: true, // 不需要登录
                title: "Product Detail",
            },
        },
        // 注册页面
        {
            path: '/SignUp',
            name: 'SignUp',
            component: () => import('@/pages/SignUp.vue'),
            meta: {
                needLogin: false, // 不需要登录
                title: "Sign Up",
            },
        },
        // 登录
        {
            path: '/Login',
            name: 'Login',
            component: () => import('@/pages/Login.vue'),
            meta: {
                needLogin: false, // 不需要登录
                title: "Log In",
            },
        },
        // 注册完成后的二维码页面
        {
            path: '/QRCode',
            name: 'QRCode',
            component: () => import('@/pages/QRCode.vue'),
            meta: {
                needLogin: true, // 不需要登录
                title: "QR Code",
            },
        },
        // 试穿页面
        {
            path: '/TryOnClothes',
            name: 'TryOnClothes',
            component: () => import('@/pages/TryOnClothes.vue'),
            meta: {
                needLogin: true, // 不需要登录
                title: "Virtual Fitting Room",
            },
        },
        // 身体编辑页面
        {
            path: '/BodyEdit',
            name: 'BodyEdit',
            component: () => import('@/pages/BodyEdit.vue'),
            meta: {
                needLogin: true, // 不需要登录
                title: "Body Edit",
            },
        },
        // 商品列表页面
        {
            path: '/ProductList',
            name: 'ProductList',
            component: () => import('@/pages/ProductList.vue'),
            meta: {
                needLogin: true,
                title: 'Product List'
            }
        },
        // 渲染测试页面
        {
            path: '/RenderTest',
            name: 'RenderTest',
            component: () => import('@/pages/RenderTest.vue'),
            meta: {
                needLogin: true,
                title: 'Product List'
            }
        }
    ]
})

router.beforeEach((to, _from, next) => {
    // 如果当前跳转目标需要登录
    if(to.meta.needLogin) {
        const userStr = localStorage.getItem('User');
        // 如果有user字符串，那么就解析出来并放行
        if(userStr) {
            const user = JSON.parse(userStr);
            const useUserStoreInstance = useUserStore();
            useUserStoreInstance.setUser(user);
            next();
        } else { // 如果没有user数据就跳去登录页
            next('/Login');
        }
    } else { // 不需要登录就直接放行
        next()
    }
})

// 修改标题的工作可以放在全局后置守卫
router.afterEach((to, _from) => {
    if (to.meta.title) {
        document.title = `NUVA | ${to.meta.title as string}`;
    }
});