import { createApp } from 'vue'
import './style.scss'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { router } from "@/router"
import { createPinia } from 'pinia'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import "./tailwindcss.css"

const app = createApp(App);

// 注册所有element plus icon
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// 创建pinia
const pinia = createPinia();

app.use(ElementPlus).use(router).use(pinia).mount('#app');
