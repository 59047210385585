import { router } from "@/router";
import { useUserStore } from "@/stores/useUserStore";
import axios from "axios";
import { ElMessage } from "element-plus";

export const request = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL,
    timeout: 100000, // 最长十分钟
})

// 添加请求拦截器
request.interceptors.request.use(
    (config) => {
        const useUserStoreInstance = useUserStore();
        // 在发送请求之前做些什么
        if(useUserStoreInstance.accessToken) { // 如果有accessToken就附带token
            config.headers['access-token'] = useUserStoreInstance.accessToken; // 添加 token 头部，如果不通过这个方法去获取token，那么这个token是不会实时变化的 
        }
        return config;
    },
    (error) => {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

let isRefreshing = false // 标记是否正在刷新 token
let requests: any[] = [] // 存储待重发请求的数组

// 响应拦截器(拦截每次请求响应之后)
request.interceptors.response.use(
    response => {
        // 如果响应的code为40302说明当前token过期了
        if(response.data.code === 40302) {
            if (!isRefreshing) { // 如果当前没有正在刷新token的请求
                isRefreshing = true;
                const useUserStoreInstance = useUserStore();
                // 进行无感刷新token
                return useUserStoreInstance.senselessRefreshToken().then(() => {
                    // token无感刷新成功了
                    return request(response.config);
                }).catch(err => {
                    // 如果当前refresh_token也过期了就回到登录页
                    ElMessage({
                        message: "The login has expired. Please log in again and then reoperate.",
                        type: "error"
                    })
                    router.push({ name: 'Login' });
                    return Promise.reject(err)
                }).finally(() => {
                    isRefreshing = false;
                    // 无感刷新完成处理之后并发存储起来的请求全部重发一遍
                    requests.forEach((cb) => cb());
                    requests = [] // 重新请求完清空
                })
            } else { // 当前有正在刷新token的请求，需要把当前请求添加到待刷新列表中，等待正在刷新token的请求完成统一重发请求
                return new Promise(resolve => {
                    // 添加到待重发列表
                    requests.push(() => {
                        resolve(request(response.config));
                    })
                })
            }
        } else { // 如果当前code不是40302
            return response;
        }
    },
    error => {
        return Promise.reject(error);
    }
);